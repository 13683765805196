import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Link } from "gatsby"

import NavInn from "../components/inner/navInn"
import Hero from "../components/common/hero"
import Btn from "../components/common/button"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 Error, Page Not Found.." />

    <Hero className="hero--inn hero--error">
      <div className="cont--spaced">
        <div className="hero__logo__cont">
          <a href="/" className="hero__logo hero__logo--withtext">PrimeSet</a>
        </div>
        <div className="hero--error__content">
          <h1 className="title--error__sub">404 Error</h1>
          <h1 className="title--error">Page not found</h1>
          <p className="hero__text">The page you requested is not found...</p>
        </div>
      </div>

      <div className="hero__btns">
        <Btn btnTitle="Go back to homepage" className="btn--text" Link btnLink="/">
        </Btn>
        <Btn btnTitle="Contact us" className="btn--prim" Link btnLink="/contact" />
      </div>
    </Hero>
    
    
  </Layout>
)

export default NotFoundPage
